.nav-bar {
  padding: 1.5em;
  position: fixed;
  width: 14em;
  height: 100%;
  left: 0;
  top: 4.5em;
  background-color: var(--secondary-bg-color);
}

.nav-bar__list {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
}

.nav-bar__item {
  padding: 0.5em 0;
}

.nav-bar__item a {
  width: 100%;
  height: 100%;
}

.nav-bar__item:hover {
  text-decoration: underline;
  font-weight: bold;
}
