.primary-button {
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  padding: 0.4em 0.8em;
  color: white;
  font: inherit;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.primary-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.primary-button__start-icon {
  margin-right: 0.7em;
}

.primary-button__end-icon {
  margin-left: 0.7em;
}
