@font-face {
  font-family: "foodhouse";
  src: url("~assets/fonts/foodhouse.eot?vrgap1");
  src: url("~assets/fonts/foodhouse.eot?vrgap1#iefix")
      format("embedded-opentype"),
    url("~assets/fonts/foodhouse.ttf?vrgap1") format("truetype"),
    url("~assets/fonts/foodhouse.woff?vrgap1") format("woff"),
    url("~assets/fonts/foodhouse.svg?vrgap1#foodhouse") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "foodhouse" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: "\f060";
}
.icon-user:before {
  content: "\f007";
}
.icon-shopping-cart:before {
  content: "\f07a";
}
