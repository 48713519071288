.icon-button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50%;
  padding: 0.5em;
  color: inherit;
  font: inherit;
}

.icon-button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
}
