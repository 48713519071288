.product-details {
  display: flex;
  margin-bottom: 3em;
}

.product-details__illustration {
  width: 40%;
  height: auto;
  margin-right: 3em;
  display: flex;
  align-items: center;
}

.product-details__illustration img {
  width: 100%;
}

.product-details__content {
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.product-details__title {
  margin-bottom: 0.5em;
}

.product-details__cart-button {
  margin-top: 2em;
  margin-bottom: 2em;
}
