.header {
  background-color: var(--primary-color);
  padding: 1em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 4.5em;
}

.header__button {
  margin-right: 1em;
  position: relative;
}

.header__button:last-child {
  margin-right: 0;
}

.header__cart-items {
  position: absolute;
  background: var(--light-color);
  color: var(--main-txt-color);
  border-radius: 50%;
  font-size: 0.8em;
  font-weight: 600;
  top: -5px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-family: "Open sans", sans-serif;
}
