@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Work+Sans:wght@300;400;500;600;700&display=swap);
:root {
  --main-bg-color: white;
  --main-txt-color: black;
  --primary-color: #1d441b;
  --secondary-color: #4ba347;
  --light-color: #fffbce;
  --secondary-bg-color: #f3fcf1;
}

@font-face {
  font-family: "foodhouse";
  src: url(/static/media/foodhouse.59a56a39.eot);
  src: url(/static/media/foodhouse.59a56a39.eot#iefix)
      format("embedded-opentype"),
    url(/static/media/foodhouse.1f9905a1.ttf) format("truetype"),
    url(/static/media/foodhouse.1942803b.woff) format("woff"),
    url(/static/media/foodhouse.9aa3b76c.svg#foodhouse) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "foodhouse" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: "\f060";
}
.icon-user:before {
  content: "\f007";
}
.icon-shopping-cart:before {
  content: "\f07a";
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-txt-color);
  background-color: var(--main-bg-color);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h1 {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
}

h2 {
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
}

h3 {
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
}

h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

h5 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

p {
  font-size: 0.9em;
}

.App__content {
  margin-left: 14em;
  margin-top: 4.5em;
  padding: 1.5em 2em;
}

.header {
  background-color: var(--primary-color);
  padding: 1em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 4.5em;
}

.header__button {
  margin-right: 1em;
  position: relative;
}

.header__button:last-child {
  margin-right: 0;
}

.header__cart-items {
  position: absolute;
  background: var(--light-color);
  color: var(--main-txt-color);
  border-radius: 50%;
  font-size: 0.8em;
  font-weight: 600;
  top: -5px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-family: "Open sans", sans-serif;
}

.icon-button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50%;
  padding: 0.5em;
  color: inherit;
  font: inherit;
}

.icon-button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
}

.link {
  text-decoration: none;
  color: inherit;
}

.nav-bar {
  padding: 1.5em;
  position: fixed;
  width: 14em;
  height: 100%;
  left: 0;
  top: 4.5em;
  background-color: var(--secondary-bg-color);
}

.nav-bar__list {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
}

.nav-bar__item {
  padding: 0.5em 0;
}

.nav-bar__item a {
  width: 100%;
  height: 100%;
}

.nav-bar__item:hover {
  text-decoration: underline;
  font-weight: bold;
}

.price {
  color: var(--primary-color);
}

.primary-button {
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  padding: 0.4em 0.8em;
  color: white;
  font: inherit;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.primary-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.primary-button__start-icon {
  margin-right: 0.7em;
}

.primary-button__end-icon {
  margin-left: 0.7em;
}

.product-card {
  background-color: var(--secondary-bg-color);
  border: 1px solid var(--secondary-bg-color);
  border-radius: 5px;
}

.product-card:hover {
  border: 1px solid var(--secondary-color);
  cursor: pointer;
}

.product-card__illustration {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
}

.product-card__details {
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.product-card__price {
  color: var(--primary-color);
}

.product-details {
  display: flex;
  margin-bottom: 3em;
}

.product-details__illustration {
  width: 40%;
  height: auto;
  margin-right: 3em;
  display: flex;
  align-items: center;
}

.product-details__illustration img {
  width: 100%;
}

.product-details__content {
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.product-details__title {
  margin-bottom: 0.5em;
}

.product-details__cart-button {
  margin-top: 2em;
  margin-bottom: 2em;
}

.products-list__title {
  margin-bottom: 1em;
}

.products-list__items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
  grid-gap: 1em;
}

.recommendations__title {
  margin-bottom: 1em;
}

.recommendations__list {
  display: flex;
  flex-direction: row;
}

.recommendations__list .product-card {
  margin-right: 1em;
}

.recommendations__list .product-card:last-child {
  margin-right: 0;
}

.secondary-button {
  background-color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0.4em 0.8em;
  color: inherit;
  font: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.secondary-button:hover {
  cursor: pointer;
  border: 1px solid var(--secondary-color);
}

.secondary-button__start-icon {
  margin-right: 0.7em;
}

.secondary-button__end-icon {
  margin-left: 0.7em;
}

